/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:after,blockquote:before,q:after,q:before{content:'';content:none}table{border-collapse:collapse;border-spacing:0}



button {
	cursor: pointer;
}

/* main global css */

/* html elements */
html, body {
	direction: rtl;
	
	color: #000;
	background:#f6f6f6;
}
h1 {
	/*font-size: 180px;
    font-size: 9.2vw;*/
    /*font-size: 8vw;*/
    /*line-height: .7;*/
    font-size: 75px;
    font-size: 45px;
    font-size: 2.4vw;
    line-height: 1;
    padding-bottom: 1vw;
}

.ac-dir-ltr {
	direction: ltr;
}

h2 {
    font-size: 75px;
    font-size: 45px;
    font-size: 2.4vw;
    line-height: 1;
    padding-bottom: 1vw;

}

h3 {
	font-size: 2.6vw;
}

strong.ac-make-strong-bigger {
	font-size: 1.2em;
}
h4 {}
h5 {}
h6 {}

p {
    font-size: 20px;
    font-size: 1vw;
    padding-bottom: 1vw;
    line-height: 1.2;
}

.ac-iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.ac-iframe-container  iframe ,
.ac-iframe-container  video  {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.ac-text-align-center {
	text-align: center;
}
.ac-text-align-right {
	text-align: right;
}


/* flex */
.ac-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ac-flex-wrap {
	-ms-flex-wrap:wrap;
	flex-wrap:wrap
}
.ac-flex-row {
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-ms-flex-direction:row;
	flex-direction:row;
}

.ac-flex-column {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.ac-flexalign-center {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.ac-flexalign-start {
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.ac-flexalign-end {
	-ms-flex-item-align: end;
	align-self: flex-end;
}
.ac-alignitems-start {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.ac-justify-spacebetween {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.ac-justify-space-even {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-evenly;
}

.ac-justify-center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.ac-justify-start {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start; 
}

.ac-justify-end {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end; 
}

.ac-flex-reverse{
    flex-direction: row-reverse;
}

/* end flex */

.ac-container-component  {
	width: 80vw;
	margin: 0 auto
}

.ac-container-component.ac-big-container-component {
	width: 90vw;
}

.ac-container-component.ac-full-width-container-component {
	width: 100%;
}
/*app*/

.ac-full-page-height {
	height: 100vh;
}

.ac-section-component  {
	position: relative;
}

.ac-img-wrapper-component {
	width: 100%;
}
.ac-img-wrapper-component  img {
	width: 100%;
}

.ac-white-color {
	color: #fff;
}

.ac-button-component {
    border: 0;
    color: #6E6E6E;
    width: 100%;
    font-size: 1vw;
    text-decoration: none;
    padding-top: .2vw;
    padding-bottom: .2vw;
    border-radius: .5vw;
    background: rgb(189,30,13);
    background: transparent;
    top: 0;
    position: relative;
    transition: .5s top;
    border: .13vw solid #6E6E6E;
    padding-right: .5vw;
    padding-left: .5vw;
    border-radius: .3vw;
    font-weight: bold
}

.ac-button-with-bg {
    background: #FF6B07;
    width: auto;
    border: 0;
    color: #fff;
    border-radius: 2vw;
    padding-right: 1vw;
    padding-left: 1vw;
    font-family: 'NarkisBlockMF-Bold';
    height: 2vw;
    margin: auto;
}

.ac-button-component:hover {

}


.ac-section-padding {
	padding-top: 1vw;
	padding-bottom: 2vw;
}
.ac-show-on-mobile {
	display: none;
}

@media all  and (max-width: 1000px) {
	.ac-hide-on-mobile {
		display: none;
	}

	.ac-show-on-mobile {
		display: block;
	}

	.ac-show-on-mobile.ac-show-flex-on-mobile {
		display: flex;
	}
	
	
	.ac-container-component {
	    width: 95%;
	}

	h1, h2 {
	    font-size: 7.4vw;
	    padding-bottom: 2vw;
	}
	p {
	    font-size: 4.5vw;
	    font-size: 4vw;
	    letter-spacing:0;
        padding-bottom: 4vw;
	} 
	.ac-ios p {
		font-size: 3.6vw;
	}
	.ac-button-component {
		font-size: 3vw;
		padding-right: 1.5vw;
		padding-left: 1.5vw;
		border-radius: .8vw;
		border: .45vw solid #6E6E6E;
	}

	.ac-container-component.ac-big-container-component {

	    /*border-top-left-radius: 5vw;
	    border-top-right-radius: 5vw;*/
		border-radius: 5vw;
		overflow: hidden;

	}

	.ac-button-with-bg {
		border-radius:8vw;
		border: 0;
		height: auto;
		padding-top: 0.5vw;
	}

	.ac-ios .ac-section-component-part-Cookie p ,
	.ac-ios .ac-floating-call-to-action p {
		letter-spacing: -1px!important
	}

	.ac-iframe-container  video {
		object-fit:cover;
	}

	.ac-section-component-part-form .ac-button-with-bg {
	    height: 9.4vw!important;
	    width: auto!important;
	    font-size: 5vw!important;
	}	

	.ac-section-component-part-form .ac-form-row .ac-col-component {
		text-align: center!important;
	}

	.ac-section-component-part-form .ac-cusotm-tkanon-wrapper .ac-cusotm-checkbox {
		width: 4vw!important;
		height: 4vw!important;
	}
	.ac-section-component-part-4 .ac-app-box-component,
	.ac-section-component-part-3 .ac-app-box-component  {
		background: transparent;
	}
	.ac-app-box-component .ac-box-text-col {
		background-color: #fff
	}
	.ac-iframe-container video {
		width:103%;
	}
	.ac-big-container-component.ac-app-box-component .ac-box-icons-row {
	        justify-content: space-evenly;
	    margin-top: -1vw;
	    width: 90%!important;
	    background: #fff;
	    padding: 9vw 4% 0vw 6%;
	}

}

.ac-section-component-part-form .ac-input-component input, .ac-section-component-part-form .ac-input-component textarea {
	border: 0!important
}
:focus{
	outline: none!important
}
/* End Yoga */

